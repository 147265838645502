import React from 'react';

export default function(__params = {}) {
  const { LinksList, links } = __params;
  return (
    <>
      <div className="outer" id="header_wrap">
        <header className="inner">
          <a id="forkme_banner" href="https://github.com/OscardR">
            View on GitHub
          </a>
          <h1 id="project_title">OscardR</h1>
          <h2 id="project_tagline">Homepage @ GitHub</h2>
        </header>
      </div>
      <div className="outer" id="main_content_wrap">
        <section className="inner" id="main_content">
          <div className="alert alert-warning">
            <p className="lead">
              <strong>404</strong> Not Found
            </p>
          </div>
          <p>It seems the page you are looking for is not there anymore (or it never was 😅)</p>
          <p>
            Use the links below to access pages that <em>do</em> exist:
          </p>
          <LinksList links={links}></LinksList>
        </section>
      </div>
      <div className="outer" id="footer_wrap">
        <footer className="inner">
          <p>
            Published with <a href="http://pages.github.com">GitHub Pages</a>
          </p>
        </footer>
      </div>
    </>
  );
}
